import Vue from 'vue'
import Vuex from 'vuex'
import TagsService from '../../services/TagsService'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    tags: [],
    active: {},
    filters: {
      resellerId: '',
      trainerId: ''
    }
  },
  mutations: {
    setTags (state, data) {
      state.tags = data
    },
    setActive (state, data) {
      state.active = data
    },
    setFilters (state, data) {
      state.filters = data
    }
  },
  actions: {
    loadTags ({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true, { root: true })
        const tagsService = new TagsService()
        tagsService
          .getAll()
          .then((res) => {
            commit('setTags', res.data.tags)

            if (
              Object.keys(state.active).length === 0 &&
                            state.tags.length !== 0
            ) {
              // first load
              state.active = state.tags[0]
            } else {
              // refresh active feature
              state.tags.forEach((item) => {
                if (item.id === state.active.id) {
                  state.active = item
                }
              })
            }

            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            commit('setLoading', false, { root: true })
          })
      })
    }
  },
  getters: {
    getTags (state) {
      return state.tags
    },
    getActive (state) {
      return state.active
    },
    getFilters (state) {
      return state.filters
    }
  }
}
