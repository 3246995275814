<template>
    <div>
        <h5>Filters:</h5>
        <b-form inline class="mb-3">
            <label class="sr-only">Reseller ID</label>
            <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Reseller Id"
                    v-model="filters.resellerId"
            ></b-form-input>

            <label class="sr-only">Trainer ID</label>
            <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input v-model="filters.trainerId" placeholder="Trainer ID"></b-form-input>
            </b-input-group>
        </b-form>
    </div>
</template>

<script>
  export default {
    name: 'AppFilter',
    data () {
      return {
        filters: {
          resellerId: '',
          trainerId: ''
        }
      }
    },
    watch: {
      filters: {
        handler: function () {
          this.$store.commit('tagsStore/setFilters', this.filters)
        },
        deep: true
      }
    },
    methods: {
      resetFilters () {
        this.filters.resellerId = ''
        this.filters.trainerId = ''
      }
    }
  }
</script>

<style scoped>

</style>
