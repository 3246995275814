import APIService from './APIService'

export default class TagsService {
  getAll (payload = []) {
    return new Promise((resolve, reject) => {
      const api = new APIService()
      api.get('tags', payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  setTrainer (featureId, resellerId, trainerId) {
    return new Promise((resolve, reject) => {
      const api = new APIService()
      api.post({
        reseller_id: resellerId,
        trainer_id: trainerId
      }, 'tags/' + featureId + '/trainers').then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  removeTrainer (featureId, resellerId, trainerId) {
    return new Promise((resolve, reject) => {
      const api = new APIService()
      api.delete({
        reseller_id: resellerId,
        trainer_id: trainerId
      }, 'tags/' + featureId + '/trainers').then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
