<template>
    <div>
        <app-header/>
        <div class="container mt-4">
            <b-card no-body>
                <b-tabs
                        pills
                        card
                        vertical
                        nav-wrapper-class="w-25"
                >
                    <b-tab
                            v-for="feature in features"
                            :key="feature.id"
                            :title="feature.name"
                            :active="feature.id === activeFeature.id"
                            @click="changeTag(feature)"
                    >
                        <template #title>
                            <div>
                                {{ feature.label }}
                                <span class="float-right">
                  <font-awesome-icon
                          class="split-view-icon"
                          :icon="['fas', 'chevron-right']"
                  />
                </span>
                            </div>
                        </template>
                        <b-card :title="activeFeature.label">
                            <hr>
                            <b-button
                                    v-b-modal.modal-1
                                    class="mb-3"
                            >
                                Add New
                            </b-button>
                            <app-filter ref="filter"></app-filter>
                            <b-table
                                    small
                                    striped
                                    hover
                                    bordered
                                    :items="table.items"
                                    :fields="table.fields"
                            >
                                <template #cell(options)="data">
                                    <b-button
                                            variant="danger"
                                            size="sm"
                                            @click="removeTrainer(data.item.reseller_id,data.item.trainer_id)"
                                    >
                                        <font-awesome-icon
                                                class="split-view-icon"
                                                :icon="['fas', 'times']"
                                        />
                                    </b-button>
                                </template>
                            </b-table>
                        </b-card>

                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
        <add-trainer-popup/>
    </div>
</template>

<script>
  import AppHeader from './includes/AppHeader'
  import {mapGetters, mapMutations} from 'vuex'
  import TagsService from '../services/TagsService'
  import AddTrainerPopup from './includes/AddTrainerPopup'
  import AppFilter from './includes/AppFilter'

  export default {
    name: 'Home',
    components: {AppHeader, AddTrainerPopup, AppFilter},
    computed: {
      ...mapGetters({
        features: 'tagsStore/getTags',
        filters: 'tagsStore/getFilters',
        activeFeature: 'tagsStore/getActive'
      })
    },
    watch: {
      activeFeature () {
        this.setTable()
      },
      filters: {
        handler: function () {
          this.setTable()
        },
        deep: true
      }
    },
    data () {
      return {
        table: {
          fields: [
            {
              key: 'reseller_id',
              label: 'Reseller ID',
              sortable: true
            },
            {
              key: 'trainer_id',
              label: 'Trainer ID',
              sortable: true
            },
            {
              key: 'options',
              label: 'Options',
              sortable: false
            }
          ],
          items: []
        }
      }
    },
    mounted () {
      this.$store.dispatch('tagsStore/loadTags')
    },
    methods: {
      ...mapMutations({
        setActiveTag: 'tagsStore/setActive'
      }),
      changeTag (feature) {
        this.setActiveTag(feature)
        this.$refs.filter.forEach((instance) => {
          instance.resetFilters()
        })
      },
      removeTrainer (resellerId, trainerId) {
        const c = confirm('Are you sure?')
        if (!c) {
          return
        }

        this.$store.commit('setLoading', true)
        const tagsService = new TagsService()
        tagsService.removeTrainer(
          this.activeFeature.id,
          resellerId,
          trainerId
        ).then(() => {
          this.$store.dispatch('tagsStore/loadTags')
        }).catch(() => {
          this.$store.commit('setLoading', false)
        })
      },
      setTable () {
        const rows = []
        const trainers = this.activeFeature.trainers

        for (let i = 0; i < trainers.length; i++) {
          // reseller id filter
          if (
            this.filters.resellerId &&
            !trainers[i].reseller_id.toString().includes(
              this.filters.resellerId.toString()
            )
          ) {
            continue
          }

          // trainer id filter
          if (
            this.filters.trainerId &&
            !trainers[i].trainer_id.toString().includes(
              this.filters.trainerId.toString()
            )
          ) {
            continue
          }

          rows.push({
            reseller_id: trainers[i].reseller_id,
            trainer_id: trainers[i].trainer_id
          })
        }

        this.table.items = rows
      }
    }
  }
</script>

<style scoped>

</style>
