import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, TabsPlugin, CardPlugin, IconsPlugin } from 'bootstrap-vue'
Vue.use(IconsPlugin)
Vue.use(BootstrapVue)
Vue.use(TabsPlugin)
Vue.use(CardPlugin)

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(faTimes)
library.add(faChevronRight)

import globalMixin from './mixins/globalMixin'
Vue.mixin(globalMixin)

import ExceptionHandler from './services/ExceptionHandler'

Vue.config.productionTip = false

initAxiosInterceptor()

new Vue({
  render: h => h(App),
}).$mount('#app')


function initAxiosInterceptor () {
  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    const exceptionHandler = new ExceptionHandler()
    exceptionHandler.handleApiError(error.response)
    return Promise.reject(error)
  })
}
