import Vue from 'vue'
import Vuex from 'vuex'
import TagsStore from './modules/tagsStore'

Vue.use(Vuex)
Vue.config.debug = true

export default new Vuex.Store({
  modules: {
    tagsStore: TagsStore
  },
  state: {
    appMounted: false,
    user: {},
    accessToken: '',
    loading: false
  },
  mutations: {
    setLoading (state, payload) {
      state.loading = payload
    },
    setAppMounted (state, payload) {
      state.appMounted = payload
    },
    setUser (state, payload) {
      state.user = payload
    },
    setAccessToken (state, payload) {
      state.accessToken = payload
    },
    removeAuth (state) {
      state.user = {}
      state.accessToken = ''
    }
  },
  actions: {},
  getters: {
    getLoading (state) {
      return state.loading
    },
    getAppMounted (state) {
      return state.appMounted
    },
    checkAuth () {
      return !!localStorage.getItem('user');
    },
    getUser (state) {
      return state.user
    },
    getAccessToken (state) {
      return state.accessToken
    }
  }
})
