<template>
    <div>
        <v-loading :active="loading"
                   :can-cancel="false"
                   :is-full-page="true"></v-loading>
        <transition>
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
  import VLoading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/vue-loading.css'
  import router from './routes/index'
  import store from './store/index'
  export default {
    name: 'App',
    router,
    store,
    components: {
      VLoading
    },
    computed: {
      loading () {
        return this.$store.getters.getLoading
      }
    }
  }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
</style>
