import APIService from './APIService'
import store from '../store/index'

export default class AuthService {
  signIn (payload) {
    return new Promise((resolve, reject) => {
      const api = new APIService()
      api.post(payload, 'auth/login').then(res => {
        this.setStorageAuth(res.data.access_token, res.data.user)
        this.syncInstanceAuth()
        resolve()
      }).catch(err => {
        this.removeStorageAuth()
        this.syncInstanceAuth()
        reject(err)
      })
    })
  }

  signOut () {
    return new Promise((resolve) => {
      this.removeStorageAuth()
      this.syncInstanceAuth()
      resolve()
    })
  }

  setStorageAuth (accessToken, user) {
    localStorage.setItem('access_token', JSON.stringify(accessToken))
    localStorage.setItem('user', JSON.stringify(user))
  }

  removeStorageAuth () {
    localStorage.removeItem('access_token')
    localStorage.removeItem('user')
  }

  syncInstanceAuth () {
    store.commit('setAccessToken', JSON.parse(localStorage.getItem('access_token')))
    store.commit('setUser', JSON.parse(localStorage.getItem('user')))
  }
}
