import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'
import Login from '../components/auth/Login'
import Home from '../components/Home'
import AuthService from '../services/AuthService'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
      props: true,
      meta: {
        requiresAuth: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (typeof to.meta !== 'undefined' && to.meta.requiresAuth && !store.getters.getAccessToken) {
    const authService = new AuthService()
    authService.syncInstanceAuth()

    if (!store.getters.getAccessToken) {
      return next({ path: '/login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
