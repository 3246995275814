<template>
    <b-modal id="modal-1" title="Add Trainer"
             ok-title="Submit"
             @hidden="reset"
             @ok="submit">
        <b-form-group
            label="Reseller ID"
        >
            <b-form-input
                v-model="form.resellerId"
                type="number"
                placeholder="Reseller ID"
                v-bind:class="{ 'is-invalid': errors.reseller_id }"
                required
            ></b-form-input>
            <div class="invalid-feedback" v-if="errors.reseller_id">
                {{errors.reseller_id[0]}}
            </div>
        </b-form-group>

        <b-form-group
            label="Trainer ID"
        >
            <b-form-input
                v-model="form.trainerId"
                type="number"
                placeholder="Trainer ID"
                v-bind:class="{ 'is-invalid': errors.trainer_id }"
                required
            ></b-form-input>
            <div class="invalid-feedback" v-if="errors.trainer_id">
                {{errors.trainer_id[0]}}
            </div>
        </b-form-group>

        <!--            <b-button variant="primary" @click="submit">-->
        <!--                Submit-->
        <!--            </b-button>-->
    </b-modal>
</template>

<script>
import TagsService from '../../services/TagsService'
import { mapGetters } from 'vuex'

export default {
  name: 'AddTrainerPopup',
  computed: {
    ...mapGetters({
      activeFeature: 'tagsStore/getActive'
    })
  },
  data () {
    return {
      form: {
        resellerId: '',
        trainerId: ''
      },
      errors: {}
    }
  },
  methods: {
    reset () {
      this.form.resellerId = ''
      this.form.trainerId = ''
      this.errors = {}
    },
    submit (e) {
      e.preventDefault()

      this.$store.commit('setLoading', true)
      const tagsService = new TagsService()
      tagsService.setTrainer(
        this.activeFeature.id,
        this.form.resellerId,
        this.form.trainerId
      ).then(() => {
        this.form.resellerId = ''
        this.form.trainerId = ''
        this.$store.dispatch('tagsStore/loadTags')
        this.$bvModal.hide('modal-1')
      }).catch((error) => {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors
        }
        this.$store.commit('setLoading', false)
      })
    }
  }
}
</script>

<style scoped>

</style>
