<template>
    <main class="mr-auto ml-auto form-signin">
        <b-form class="mx-auto" @submit.prevent="signIn">
            <!--            <img class="mb-4" src="images/logo.png" alt="" width="100%">-->
            <h3 class="text-center mb-4">Platform Tag Manager v1.0</h3>
            <b-form-group
                label="Email:"
            >
                <b-form-input
                    v-model="payload.email"
                    type="email"
                    placeholder="Email"
                    v-bind:class="{ 'is-invalid': errors.email }"
                ></b-form-input>
                <div class="invalid-feedback" v-if="errors.email">
                    {{errors.email[0]}}
                </div>

            </b-form-group>

            <b-form-group label="Password:">
                <b-form-input
                    type="password"
                    v-model="payload.password"
                    placeholder="Password"
                    v-bind:class="{ 'is-invalid': errors.password }"
                ></b-form-input>
                <div class="invalid-feedback" v-if="errors.password">
                    {{errors.password[0]}}
                </div>
            </b-form-group>
            <b-button type="submit" variant="primary" class="btn-block float-right">
                <span v-if="submitting">Please wait</span>
                <span v-else>Log In</span>
                <b-spinner small v-if="submitting"></b-spinner>
            </b-button>
        </b-form>
    </main>
</template>

<script>
import AuthService from '../../services/AuthService'

export default {
  name: 'Login',
  data () {
    return {
      payload: {
        email: '',
        password: ''
      },
      errors: {
        email: '',
        password: ''
      },
      submitting: false
    }
  },
  methods: {
    signIn () {
      this.submitting = true
      const authService = new AuthService()
      authService
        .signIn(this.payload)
        .then(() => {
          this.$router.push('/')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
        })
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>

<style scoped>
    html,
    body {
        height: 100%;
        background-color: #FAFBFB !important;
    }

    .form-signin {
        padding-top: 17vh !important;
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }

    .form-signin .checkbox {
        font-weight: 400;
    }

    .form-signin .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }

    .form-signin .form-control:focus {
        z-index: 2;
    }

    .form-signin input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .form-signin input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
</style>
